<template>
  <div class="richtext-container">
    <el-form
      :model="form"
      :rules="rules"
      ref="value"
      label-width="200px"
      class="demo-ruleForm"
    >
      <el-form-item label="客服微信:" prop="contact_us.value.cs_wx">
        <el-input
          v-model="form.contact_us.value.cs_wx"
          placeholder="单行输入"
          style="width: 350px"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="客服电话:"
        prop="contact_us.value.cs_phone"
      >
        <el-input
          v-model="form.contact_us.value.cs_phone"
          placeholder="单行输入"
          style="width: 350px"
        ></el-input>
      </el-form-item>

      <el-form-item label="广告弹窗:" prop="value.contact_us.value.advertising_pop_up">
        <upload-one v-model="form.contact_us.value.advertising_pop_up" style="width:250px;height:350px"></upload-one>
      </el-form-item>

      <div style="padding-bottom:20px">
      <el-radio-group v-model="form.contact_us.value.popup" size="small"  @change="changeA">
        <el-radio :label="1" border style="margin-left:200px">开启</el-radio>
        <el-radio :label="2" border >关闭</el-radio>
      </el-radio-group>

      <el-form-item
        label="抖音链接:"
        prop="contact_us.value.cs_phone"
        style="margin-top:20px"
      >
        <el-input
          v-model="form.contact_us.value.tiktok_link"
          placeholder="单行输入"
          style="width: 350px"
        ></el-input>
      </el-form-item>
    </div>

      <el-form-item>
        <!-- <el-button>返回</el-button> -->
        <el-button type="primary" @click="saveData" :loading='btnLoading'>保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import AMapPosition from "@/components/AMapPosition";
import Tinymce from "@/components/Tinymce";
import request from "@/utils/request";

export default {
  components: {
    Tinymce,
  },
  data() {
    return {
      loading: false,
      btnLoading: false,
      
      form: {
        contact_us: {
          value: {
            cs_wx:'',
            cs_phone:'',
            advertising_pop_up:'',
            is_open_advertising_pop_up:'',
            tiktok_link:'',
            popup:"",
          },
        },
      },
      value: {
        service_wx: "",
        service_phone: "",
        cover_url: "",
        // user_agreement: "",
        // is_open_advance_ticket: 0,
      },
      rules: {
        "contact_us.value.cs_wx": [
          {
            required: true,
            message: "客服微信不能为空",
            trigger: "blur",
          },
        ],
        "contact_us.value.cs_phone": [
          {
            required: true,
            message: "客服电话不能为空",
            trigger: "blur",
          },
        ],
        "contact_us.value.advertising_pop_up": [
          {
            required: true,
            message: "请上传广告弹窗图片",
            trigger: "change",
          },
        ],
      },
      listQuery: {
        key: "distribution_config",
      },
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
     changeA(e){
       console.log(e);
     }, 
    getDetail() {
      request({
        url: "/api/backend/settings/get",
        method: "get",
        params: {
          key: "contact_us",
        },
      }).then((response) => {
        if (response.data) {
          this.form.contact_us.value = response.data.value;
        }
      });
    },
    saveData() {
      if(this.form.contact_us.value.popup=='1'){
        this.form.contact_us.value.is_open_advertising_pop_up=true;
      }else{
          this.form.contact_us.value.is_open_advertising_pop_up=false
      }  
      console.log(this.form.contact_us.value.is_open_advertising_pop_up);
      this.$refs["value"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/backend/settings/store",
            method: "post",
            data: this.form,
          })
            .then(() => {
              this.btnLoading = false;
              this.getDetail();
              this.$message({
                type: "success",
                message: "操作成功",
              });
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.richtext-container {
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  // div {
  //   flex: 1;
  // }
}
</style>
